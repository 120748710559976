import gql from 'graphql-tag'

export default gql`
query ($eventId: String! $interval: Int) {
  activitiesCount (eventId: $eventId) {
    lastActivity
    count
  }
  activitiesCountByTime (eventId: $eventId interval: $interval) {
    time
    count
  }
  activitiesCountByShirt(eventId: $eventId) {
    shirtSize
    shirtType
    count
  }
}
`
