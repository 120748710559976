import React, { useCallback, useContext } from 'react'
import {
  Typography, Card, CardContent, CardActions, Button, TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Redirect } from 'react-router-dom'
import {
  // seMutation,
  useApolloClient,
} from '@apollo/react-hooks'

import { useMultiState } from '../hooks'
import SnackbarContentWrapper from '../components/SnackbarContent'
import EventContext from '../contexts/EventContext'
// import checkinMutation from '../graphql/mutations/checkin'
import participantQuery from '../graphql/queries/participant'

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: 'user',
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    // flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  title: {
    marginBottom: '13px',
  },
  form: {
    width: '100%',
  },
  searchButton: {
    width: '256px !important',
  },
  card: {
    width: '48%',
  },
  logo: {
    margin: spacing(8),
    width: '80%',
  },
  detection: {
    height: videoConstraints.height,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  photoWrapper: {
    width: videoConstraints.width,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  photo: {
    maxWidth: videoConstraints.width,
    maxHeight: videoConstraints.height,
  },
  webcam: {
    width: videoConstraints.width,
    display: 'flex',
    justifyContent: 'center',
  },
  frame: {
    width: '10%',
  },
  extendedIcon: {
    marginRight: spacing(1),
  },
  message: {
    width: '100%',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  greeting: {
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
  },
  framesWrapper: {
    height: 96,
  },
}))

const QRScan = () => {
  const client = useApolloClient()
  // const history = useHistory()
  const classes = useStyles()
  const { event } = useContext(EventContext)
  // const [checkin] = useMutation(checkinMutation)
  const [state, setState] = useMultiState({
    search: '',
    participant: null,
    loading: false,
    error: null,
  })
  const {
    participant,
    // loading,
    error,
  } = state
  const handleSearch = useCallback(
    async () => {
      const { data } = await client.query({ query: participantQuery, variables: { eventId: event && event._id, search: state.search }, fetchPolicy: 'network-only' })
      setState({ participant: data.participant })
    }, [client, event, setState, state.search])
  const handleCheck = useCallback(
    async () => {
      // const { _id: participantId } = participant
      const x = false
      if (x) {
        try {
          // const { data } = await checkin({ variables: { participantId, status: 'shirt', descriptors: [] } })
          // if (data && data.checkin) {
          //   history.push(`/slip/${data.checkin.recordId}`)
          // }
        } catch (err) {
          console.error(err)
        }
      }
    },
    []
  )
  const action = (
    <Button color="Default" size="medium" onClick={() => handleCheck()}>
      Check In
    </Button>
  )

  if (!event) {
    return <Redirect to="/events" />
  }
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Slip
          </Typography>
          <Typography className={classes.title} variant="h5" component="h4">
            Name:
          </Typography>
          <Typography className={classes.title} variant="h5" component="h3">
            ID:
          </Typography>
          <Typography className={classes.title} variant="h5" component="h3">
            Shirt size
          </Typography>
          <Typography className={classes.title} variant="h5" component="h3">
            Bib
          </Typography>
          <form className={classes.root} noValidate onSubmit={handleSearch} autoComplete="off">
            <TextField onChange={e => setState({ search: e.target.value })} autoFocus id="outlined-basic" label="Input" variant="outlined" className={classes.form} />
          </form>
        </CardContent>
        {error ? (
          <CardActions>
            {error ? (
              <SnackbarContentWrapper
                className={classes.message}
                showIcon
                variant="error"
                message={error}
                action={undefined}
              />
            ) : (
              <SnackbarContentWrapper
                className={classes.message}
                variant="info"
                message={participant ? 'Participant Found' : 'Ready'}
                action={action}
              />
            )}
          </CardActions>
        ) : null}
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Shirt
          </Typography>
          <Typography className={classes.title} variant="h5" component="h4">
            Name:
          </Typography>
          <Typography className={classes.title} variant="h5" component="h3">
            ID:
          </Typography>
          <Typography className={classes.title} variant="h5" component="h3">
            Shirt size
          </Typography>
          <Typography className={classes.title} variant="h5" component="h3">
            Bib
          </Typography>
          <TextField id="outlined-basic" label="Outlined" variant="outlined" className={classes.form} />
        </CardContent>
        {error ? (
          <CardActions>
            {error ? (
              <SnackbarContentWrapper
                className={classes.message}
                showIcon
                variant="error"
                message={error}
                action={undefined}
              />
            ) : (
              <SnackbarContentWrapper
                className={classes.message}
                variant="info"
                message={participant ? 'Participant Found' : 'Ready'}
                action={action}
              />
            )}
          </CardActions>
        ) : null}
      </Card>
      <Button color="Default" size="medium" onClick={() => handleCheck()}>
        Confirm
      </Button>
    </div>
  )
}
export default QRScan
