import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const EventContext = createContext()

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState(null)
  useEffect(() => {
    try {
      const localEvent = JSON.parse(localStorage.getItem('event'))
      setEvent(localEvent)
    } catch (err) {
      console.error(err)
    }
  }, [setEvent])
  const updateEvent = (e) => {
    if (e) {
      localStorage.setItem('event', JSON.stringify(e))
    } else {
      localStorage.removeItem('event')
    }
    setEvent(e)
  }
  return (
    <EventContext.Provider value={{ event, setEvent: updateEvent }}>
      {children}
    </EventContext.Provider>
  )
}
EventProvider.propTypes = {
  children: PropTypes.node,
}
EventProvider.defaultProps = {
  children: null,
}

export const EventConsumer = EventContext.Consumer

export default EventContext
