import gql from 'graphql-tag'

export default gql`
mutation ($eventId: String!) {
  downloadFace (eventId: $eventId) {
    bib
    photo
  }
}
`
