import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { IconButton, SnackbarContent } from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon, Warning as WarningIcon, Error as ErrorIcon, Info as InfoIcon, Close as CloseIcon,
} from '@material-ui/icons'
import { amber, green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  success: {
    backgroundColor: green[600],
    color: '#fff',
  },
  error: {
    backgroundColor: palette.error.main,
    color: '#fff',
  },
  info: {
    backgroundColor: palette.primary.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: amber[700],
    color: '#fff',
  },
  icon: {
    fontSize: 20,
    color: '#fff',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const SnackbarContentWrapper = (props) => {
  const classes = useStyles()
  const {
    className, message, onClose, variant, showIcon, ...other
  } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={(
        <span className={classes.message}>
          {showIcon ? <Icon className={clsx(classes.icon, classes.iconVariant)} /> : null}
          {message}
        </span>
      )}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  showIcon: PropTypes.bool,
}
SnackbarContentWrapper.defaultProps = {
  className: null,
  message: null,
  onClose: () => {},
  showIcon: false,
}

export default SnackbarContentWrapper
