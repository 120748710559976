import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography, Container, Card, CardContent, CardActions, Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'

import EventContext from '../contexts/EventContext'
import Loading from '../components/Loading'
import staffEventsQuery from '../graphql/queries/staffEvents'

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    marginBottom: spacing(1),
  },
  actionButton: {
    marginLeft: 'auto',
  },
  button: {
    marginLeft: spacing(1),
  },
}))

const SelectEventPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { setEvent } = useContext(EventContext)
  const eventsResult = useQuery(staffEventsQuery)
  const selectEvent = event => (path) => {
    setEvent(event)
    history.push(path)
  }
  if (eventsResult.loading) {
    return (
      <Loading />
    )
  }
  return (
    <Container>
      {eventsResult.data.staffEvents.map(event => (
        <Card key={event._id} className={classes.card}>
          <CardContent>
            <Typography variant="h5">{event.name}</Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button className={classes.actionButton} onClick={() => selectEvent(event)('/participants')}>
              Participants
            </Button>
            <Button className={classes.button} onClick={() => selectEvent(event)('/stats')}>
              Stats
            </Button>
            <Button className={classes.button} onClick={() => selectEvent(event)('/camera')}>
              Camera
            </Button>
            <Button className={classes.button} onClick={() => selectEvent(event)('/top100')}>
              Top 100
            </Button>
            <Button className={classes.button} variant="contained" color="secondary" onClick={() => selectEvent(event)('/faceid')}>
              FaceID
            </Button>
            <Button className={classes.button} variant="contained" color="secondary" onClick={() => selectEvent(event)('/manualcheckin')}>
              Manual
            </Button>
          </CardActions>
        </Card>
      ))}
    </Container>
  )
}

export default SelectEventPage
