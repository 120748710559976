import React, { Fragment, useCallback, useContext } from 'react'
import {
  Typography, Card, CardMedia, CardContent, CardActions, TextField, InputAdornment, Fab,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search as SearchIcon } from '@material-ui/icons'
import { useApolloClient } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'

import { useMultiState } from '../hooks'
import participantQuery from '../graphql/queries/participant'
import Loading from '../components/Loading'
import SnackbarContentWrapper from '../components/SnackbarContent'
import EventContext from '../contexts/EventContext'

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: 'user',
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  form: {
    width: '100%',
  },
  searchButton: {
    width: '256px !important',
  },
  card: {
    width: videoConstraints.width * 2,
  },
  logo: {
    margin: spacing(8),
    width: '80%',
  },
  detection: {
    height: videoConstraints.height,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  photoWrapper: {
    width: videoConstraints.width,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  photo: {
    maxWidth: videoConstraints.width,
    maxHeight: videoConstraints.height,
  },
  webcam: {
    width: videoConstraints.width,
    display: 'flex',
    justifyContent: 'center',
  },
  frame: {
    width: '10%',
  },
  extendedIcon: {
    marginRight: spacing(1),
  },
  message: {
    width: '100%',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  greeting: {
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
  },
  framesWrapper: {
    height: 96,
  },
}))

const FaceIDPage = () => {
  const classes = useStyles()
  const { event } = useContext(EventContext)
  const [state, setState] = useMultiState({
    search: '',
    participant: null,
    loading: false,
    error: null,
  })
  const {
    search,
    participant,
    loading,
    error,
  } = state
  const client = useApolloClient()
  const processFaceID = useCallback(
    async (text) => {
      setState({
        participant: null,
        faceMatcher: null,
        loading: true,
        frames: [],
        error: null,
      })
      try {
        const { data } = await client.query({ query: participantQuery, variables: { eventId: event && event._id, search: text }, fetchPolicy: 'network-only' })
        if (data && data.participant) {
          setState({ participant: data.participant, error: null })
        } else {
          setState({ error: `Bib number or ID/Passport ${text} not found` })
        }
      } catch (err) {
        console.error(err)
      } finally {
        setState({ loading: false })
      }
    },
    [event, client, setState]
  )
  const handleSearch = useCallback(
    async (e) => {
      e.preventDefault()
      processFaceID(search)
    },
    [search, processFaceID]
  )
  if (!event) {
    return <Redirect to="/events" />
  }
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={handleSearch} className={classes.form}>
          <TextField
            autoFocus
            fullWidth
            label="Search"
            placeholder="Enter bib number"
            variant="filled"
            style={{ margin: 0 }}
            value={search}
            onChange={e => setState({ search: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Search"
                    onClick={handleSearch}
                    disabled={search === ''}
                  >
                    <SearchIcon className={classes.extendedIcon} />
                    Search
                  </Fab>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {participant ? (
          <Fragment>
            <div className={classes.detection}>
              <CardMedia className={classes.photoWrapper}>
                <img className={classes.photo} src={participant.photo || event.logo} alt="Participant" />
              </CardMedia>
              <CardMedia className={classes.photoWrapper}>
                <img className={classes.photo} src={(participant.lastActivity && participant.lastActivity.photo) || event.logo} alt="Participant" />
              </CardMedia>
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                {`${participant.firstname} ${participant.lastname} (${participant.bib})`}
              </Typography>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                ID: {participant.nationalId}
              </Typography>
              <Typography variant="h6" color="textSecondary" component="h4" align="center">
                {participant.ageCategory}
              </Typography>
            </CardContent>
          </Fragment>
        ) : (
          <Fragment>
            <CardMedia className={classes.greeting}>
              <img className={classes.logo} src={event.logo} alt={event.name} />
              {loading && (<Loading />)}
            </CardMedia>
          </Fragment>
        )}
        {participant || error ? (
          <CardActions>
            {error ? (
              <SnackbarContentWrapper
                className={classes.message}
                showIcon
                variant="error"
                message={error}
                action={undefined}
              />
            ) : (
              <SnackbarContentWrapper
                className={classes.message}
                variant="info"
                message={participant ? 'Participant found' : 'Ready'}
                action={undefined}
              />
            )}
          </CardActions>
        ) : null}
      </Card>
    </div>
  )
}
export default FaceIDPage
