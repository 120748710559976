import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

import { checkTokenExpired } from './contexts/AuthContext'

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const { token, user } = checkTokenExpired(localStorage.getItem('token'))
    if (token && user) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    }
    return { headers }
  })
  return forward(operation)
})
const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.error({ graphQLErrors })
      }
      if (networkError) {
        console.error({ networkError })
        // if (networkError.statusCode === 401) {
        //   removeToken()
        // }
      }
    }),
    authMiddleware,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVER,
    }),
  ]),
  cache: new InMemoryCache(),
})

export default client
