import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { ListItem, ListItemText } from '@material-ui/core'
import { LayoutContext } from 'bearright-mui-layout'

const ListMenuItem = (props) => {
  const { path, title, className } = props
  const { pathname } = useLocation()
  const { setOpen } = useContext(LayoutContext)
  return (
    <ListItem
      button
      className={className}
      to={path}
      component={Link}
      selected={pathname === path}
      onClick={() => setOpen(false)}
    >
      <ListItemText primary={title} />
    </ListItem>
  )
}
ListMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
ListMenuItem.defaultProps = {
  className: null,
}

export default ListMenuItem
