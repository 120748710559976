import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ThemeContext } from 'bearright-mui-layout'

const useStyles = makeStyles(({ spacing }) => ({
  footerLarge: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerLogo: {
    maxHeight: spacing(3),
    marginBottom: spacing(-0.5),
  },
  noBottom: {
    marginBottom: 0,
  },
}))

const Footer = (props) => {
  const { poweredBy, copyright } = props
  const classes = useStyles()
  const matches = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'))
  const { theme } = useContext(ThemeContext)
  return (
    <div className={!poweredBy || !copyright || matches ? classes.footerSmall : classes.footerLarge}>
      {poweredBy ? (
        <Typography className={classes.noBottom} variant="body1" color="inherit" noWrap>
          Powered by <img className={classes.footerLogo} src={`/images/logo-${theme}-64.png`} alt={poweredBy} /> {poweredBy}.
        </Typography>
      ) : null}
      {copyright ? (
        <Typography className={classes.noBottom} variant="body2" color="inherit" noWrap>{copyright}</Typography>
      ) : null}
    </div>
  )
}
Footer.propTypes = {
  poweredBy: PropTypes.string,
  copyright: PropTypes.string,
}
Footer.defaultProps = {
  poweredBy: null,
  copyright: null,
}

export default Footer
