import {
  useState, useEffect, useRef, useMemo,
} from 'react'

export const useInterval = (callback, delay = 1000) => {
  const savedCallback = useRef()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export const useMultiState = (initState) => {
  const [state, setState] = useState(initState)
  const updateState = useMemo(() => (newState) => {
    if (typeof newState === 'function') {
      setState(prevState => ({ ...prevState, ...newState(prevState) }))
    } else {
      setState(prevState => ({ ...prevState, ...newState }))
    }
  }, [setState])
  return [state, updateState]
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
