import gql from 'graphql-tag'

export default gql`
query {
  staffEvents {
    _id
    name
    organizer {
      name
    }
    logo
    slug
    shirts {
      slug
      type
      image
    }
    tickets {
      ticketType
      dateTicket
    }
    options {
      search {
        idcard
        qrcode
      }
      face {
        idcard
        upload
      }
      print
    }
    steps
  }
}
`
