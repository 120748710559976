import React, {
  useContext, useEffect, useRef, useCallback, useMemo,
} from 'react'
import { Redirect } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Webcam from 'react-webcam'
import moment from 'moment'

import EventContext from '../contexts/EventContext'
import { loadModels, getFullFaceDescription, bufferToImage } from '../face'
import { useInterval, useMultiState } from '../hooks'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  panel: {
    position: 'relative',
    width: videoConstraints.width,
    height: videoConstraints.height,
  },
  webcam: {
    display: 'flex',
    position: 'absolute',
  },
  border: {
    display: 'flex',
    position: 'absolute',
    border: `4px solid ${palette.primary.main}`,
  },
  borderUnknown: {
    display: 'flex',
    position: 'absolute',
    border: `4px solid ${palette.secondary.main}`,
  },
  label: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    alignSelf: 'flex-end',
    borderTop: `4px solid ${palette.primary.main}`,
    width: '100%',
  },
}))

const initialState = {
  faces: [],
}

const CameraPage = () => {
  const classes = useStyles()
  const [state, setState] = useMultiState(initialState)
  const { event } = useContext(EventContext)
  const webcamRef = useRef(null)
  const { faces } = state
  useEffect(() => {
    const init = async () => {
      try {
        await loadModels()
      } catch (err) {
        console.error(err)
      }
    }
    init()
  }, [])
  const capture = useCallback(
    async () => {
      if (webcamRef.current) {
        const image = webcamRef.current.getScreenshot()
        if (image) {
          const blob = new Blob([Buffer.from(image.replace(new RegExp(/data:image\/jpeg;base64,/,), ''), 'base64')])
          const img = await bufferToImage(blob)
          const detect = await getFullFaceDescription(img)
          if (detect.length > 0) {
            const descriptors = detect.map(face => Array.from(face.descriptor))
            const response = await fetch(process.env.REACT_APP_RECOG_SERVER, {
              method: 'POST',
              body: JSON.stringify({
                eventId: event._id,
                descriptors,
                distance: 0.4,
                time: moment(),
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            })
            const recognition = await response.json()
            setState({ faces: recognition.map((recog, index) => ({ ...recog, ...detect[index] })) })
          } else {
            setState({ faces: [] })
          }
        }
      }
    },
    [webcamRef, setState, event._id]
  )
  useInterval(() => {
    capture()
  }, 333)
  const camera = useMemo(() => (
    <Webcam
      audio={false}
      width={videoConstraints.width}
      height={videoConstraints.height}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      videoConstraints={videoConstraints}
    />
  ), [webcamRef])
  if (!event) {
    return <Redirect to="/events" />
  }
  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <div className={classes.webcam}>
          {camera}
          {faces.map(face => (
            <div
              key={`${face.label}-${face.distance}`}
              className={face.label !== 'unknown' ? classes.border : classes.borderUnknown}
              style={{
                height: face.detection.box.height,
                width: face.detection.box.width,
                transform: `translate(${face.detection.box.x}px,${face.detection.box.y}px)`,
              }}
            >
              {face.label !== 'unknown' ? (
                <Typography className={classes.label} variant="h6">{face.label}</Typography>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CameraPage
