import * as faceapi from 'face-api.js'

export const loadModels = async () => {
  // const MODEL_URL = `${process.env.PUBLIC_URL}/models`
  const MODEL_URL = 'https://supachaic.github.io/react-face-recognition/models'
  await faceapi.loadTinyFaceDetectorModel(MODEL_URL)
  await faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
  await faceapi.loadFaceRecognitionModel(MODEL_URL)
}

const scoreThreshold = 0.4
const inputSize = 512
const faceDetectorOptions = new faceapi.TinyFaceDetectorOptions({
  inputSize,
  scoreThreshold,
})
const useTinyModel = true
export const getFullFaceDescription = async (img) => {
  const fullDesc = await faceapi
    .detectAllFaces(img, faceDetectorOptions)
    .withFaceLandmarks(useTinyModel)
    .withFaceDescriptors()
  return fullDesc
}

const distanceThreshold = 0.4
export const createFaceMatcher = (label, descriptors) => new faceapi.FaceMatcher([
  new faceapi.LabeledFaceDescriptors(label, descriptors),
], distanceThreshold)
export const loadFaceMatcher = labeledDescriptors => faceapi.FaceMatcher.fromJSON({ labeledDescriptors, distanceThreshold })

export const fetchImage = async uri => faceapi.fetchImage(uri)
export const bufferToImage = async buf => faceapi.bufferToImage(buf)
export const { createCanvasFromMedia, draw: { drawDetections } } = faceapi
