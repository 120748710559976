import gql from 'graphql-tag'

export default gql`
query ($eventId: String! $row: String $status: String) {
  participantReport (eventId: $eventId row: $row status: $status) {
    _id
    firstname
    lastname
    bib
    row
    lastActivity
    timestamp
    event {
      name
    }
    nationalId
    shirttype
    shirtsize
    options
  }
}
`
