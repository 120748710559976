import React, { useContext } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import TitleContext from '../contexts/TitleContext'

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
})

const Title = () => {
  const classes = useStyles()
  const { title } = useContext(TitleContext)
  return (
    <Typography variant="h6" color="inherit" noWrap className={classes.grow}>{title}</Typography>
  )
}

export default Title
