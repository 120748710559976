import React, {
  Fragment, useCallback, useEffect, useRef, useContext, useMemo,
} from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import {
  Typography, Card, CardMedia, CardContent, CardActions, TextField, InputAdornment, Button, Fab
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search as SearchIcon, RotateLeft as RotateLeftIcon } from '@material-ui/icons'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import Webcam from 'react-webcam'
import io from 'socket.io-client'
import moment from 'moment'

import { useMultiState, useInterval, usePrevious } from '../hooks'
import {
  loadModels, fetchImage, getFullFaceDescription, createFaceMatcher, bufferToImage, createCanvasFromMedia, drawDetections,
} from '../face'
import participantQuery from '../graphql/queries/participant'
import checkinMutation from '../graphql/mutations/checkin'
import Loading from '../components/Loading'
import SnackbarContentWrapper from '../components/SnackbarContent'
import EventContext from '../contexts/EventContext'

const RECOGNITION_TIMEOUT = 5000
const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: 'user',
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  form: {
    width: '100%',
  },
  searchButton: {
    width: '256px !important',
  },
  clearButton: {
    marginLeft: spacing(1),
  },
  card: {
    width: videoConstraints.width * 2,
  },
  logo: {
    margin: spacing(8),
    width: '80%',
  },
  detection: {
    height: videoConstraints.height,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  photoWrapper: {
    width: videoConstraints.width,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  photo: {
    maxWidth: videoConstraints.width,
    maxHeight: videoConstraints.height,
  },
  webcam: {
    width: videoConstraints.width,
    display: 'flex',
    justifyContent: 'center',
  },
  frame: {
    width: '10%',
  },
  extendedIcon: {
    marginRight: spacing(1),
  },
  message: {
    width: '100%',
    fontSize: 24,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  greeting: {
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
  },
  framesWrapper: {
    height: 96,
  },
}))
const blank = {
  search: '',
  participant: null,
  faceMatcher: null,
  loading: false,
  frames: [],
  error: null,
  warning: null,
  timeoutId: null,
}
const FaceIDPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { event } = useContext(EventContext)
  const webcamRef = useRef(null)
  const [state, setState] = useMultiState({
    search: '',
    participant: null,
    faceMatcher: null,
    loading: false,
    frames: [],
    error: null,
    warning: null,
    timeoutId: null,
    cardReaderConnected: false,
  })
  const {
    search,
    participant,
    faceMatcher,
    loading,
    frames,
    error,
    warning,
    timeoutId,
    cardReaderConnected,
  } = state
  const prevState = usePrevious({ faceMatcher, frames })
  const client = useApolloClient()
  const [checkin] = useMutation(checkinMutation)
  const socket = useMemo(() => io(process.env.REACT_APP_ID_CARD_SOCKET, {
    reconnection: false,
  }), [])
  useEffect(() => {
    const init = async () => {
      await loadModels()
    }
    init()
  }, [])
  const handleCheckin = useCallback(
    async () => {
      const { _id: participantId } = participant
      const bestPhoto = frames.reduce((prev, cur) => {
        if (cur.face.distance < prev.distance) {
          return {
            distance: cur.face.distance,
            label: cur.face.label,
            photo: cur.image,
            index: cur.key,
          }
        }
        return prev
      }, {
        distance: 1, label: null, photo: null, index: -1,
      })
      const descriptors = frames.map(({ face: { descriptor } }) => Array.from(descriptor))
      try {
        const response = await fetch(process.env.REACT_APP_UPLOAD_SERVER, {
          method: 'POST',
          body: JSON.stringify({
            photo: bestPhoto.photo,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        const result = await response.json()
        const { data } = await checkin({
          variables: {
            participantId, status: bestPhoto.label, descriptors, photo: result.url,
          },
        })
        if (data && data.checkin) {
          history.push(`/slip/${data.checkin.recordId}`)
        }
      } catch (err) {
        console.error(err)
      }
    },
    [history, checkin, participant, frames]
  )
  const processFaceID = useCallback(
    async (text, { idCardPhoto, gender } = {}) => {
      setState({
        participant: null,
        faceMatcher: null,
        loading: true,
        frames: [],
        error: null,
        warning: null
      })
      try {
        const { data } = await client.query({ query: participantQuery, variables: { eventId: event && event._id, search: text }, fetchPolicy: 'network-only' })
        const newState = { matcher: null, error: null, warning: null }
        if (data && data.participant) {
          newState.participant = data.participant
          let descriptors = []
          if (idCardPhoto) {
            try {
              const img = await fetchImage(idCardPhoto)
              const detections = await getFullFaceDescription(img)
              descriptors = [...descriptors, ...detections]
            } catch (err) {
              console.error(err)
            }
          }
          if (data.participant.photo) {
            try {
              const img = await fetchImage(data.participant.photo)
              const detections = await getFullFaceDescription(img)
              descriptors = [...descriptors, ...detections]
              setTimeout(() => {
                const photoNode = document.getElementById('photo')
                const canvas = createCanvasFromMedia(photoNode)
                drawDetections(canvas, detections)
                canvas.className = classes.photo
                document.getElementById('photo-wrapper').replaceChild(canvas, photoNode)
              }, 1000)
            } catch (err) {
              console.error(err)
            }
          }
          if (descriptors.length > 0) {
            newState.faceMatcher = createFaceMatcher('checkin', descriptors.map(detection => detection.descriptor))
          } else {
            newState.warning = 'Can not detect face'
          }
          if (gender && data.participant.gender) {
            if (gender.toLowerCase() !== data.participant.gender.toLowerCase()) {
              newState.error = 'เพศไม่ตรงกัน'
            }
          }
          if (data.participant.options && data.participant.options.children) {
            newState.warning = 'Children checkin'
          }
          if(data.participant.options && data.participant.options.ticketType){

            if(data.participant.options.ticketType.includes('Online') === true ){
              event.tickets.forEach((ele) => {
                if(ele.ticketType === data.participant.options.ticketType){
                  
                  newState.error = 'Online Runner'
                }
              })
            }else if(data.participant.options.ticketType.includes('KIT') === true ){
              event.tickets.forEach((ele) => {
                if(ele.ticketType === data.participant.options.ticketType){
                  
                  newState.error = 'RACE KIT'
                }
              })
            } else if((data.participant.options.ticketType.includes('รับบิบได้ทั้ง') || data.participant.options.ticketType.includes('หรือ') || data.participant.options.ticketType.includes('or')) !== true){
              event.tickets.forEach((ele) => {
                if(ele.ticketType === data.participant.options.ticketType){
                  // console.log('eqticket')
                  // console.log(moment(ele.dateTicket))
                  // const newDay = moment().add(16, 'days').format('YYYY-MM-DD')
                  // console.log(newDay)
                  if(!moment(ele.dateTicket).isSame(moment().format('YYYY-MM-DD'))){
                    newState.error = `วันรับบิบไม่ตรง (${moment(ele.dateTicket).format('DD/MM/YYYY')})`
                  }
                }
              })
            } 
            
          }
        // if (age && data.options) {
        //   if (age !== data.options.age) {
        //     newState.error = 'Age does not match'
        //   }
        // }
        } else {
          newState.warning = `Bib number or ID/Passport ${text} not found`
        }
        setState(newState)
      } catch (err) {
        console.error(err)
      } finally {
        setState({ loading: false })
      }
    },
    [event, client, setState, classes]
  )
  const handleSearch = useCallback(
    async (e) => {
      e.preventDefault()
      processFaceID(search)
    },
    [search, processFaceID]
  )
  const handleCardReader = useCallback(
    async ({
      nationalId, idCardPhoto, gender, age, birthdate,
    }) => {
      processFaceID(nationalId, {
        idCardPhoto, gender, age, birthdate,
      })
    },
    [processFaceID]
  )
  const manualCheckin = useCallback(
    async () => {
      setState({
        faceMatcher: null, frames: [], error: null, warning: null,
      })
      const newState = { warning: 'Can not detect face, Please try again!' }
      if (webcamRef.current) {
        const image = webcamRef.current.getScreenshot()
        if (image) {
          try {
            const blob = new Blob([Buffer.from(image.replace(new RegExp(/data:image\/jpeg;base64,/,), ''), 'base64')])
            const img = await bufferToImage(blob)
            const detections = await getFullFaceDescription(img)
            if (detections.length > 0) {
              newState.faceMatcher = createFaceMatcher('manualcheckin', detections.map(detection => detection.descriptor))
              newState.error = null
              newState.warning = null
            }
          } catch (err) {
            console.error(err)
          }
        }
      }
      setState(newState)
    },
    [webcamRef, setState]
  )
  const childrenCheckin = useCallback(
    async () => {
      const { _id: participantId } = participant
      try {
        const { data } = await checkin({
          variables: {
            participantId, status: 'childrencheckin', descriptors: [],
          },
        })
        if (data && data.checkin) {
          history.push(`/slip/${data.checkin.recordId}`)
        }
      } catch (err) {
        console.error(err)
      }
    },
    [history, checkin, participant]
  )
  const resumeCheckin = useCallback(
    async () => {
      const id = setTimeout(() => {
        setState({ warning: 'Recognition timeout, Please manual check-in!' })
      }, RECOGNITION_TIMEOUT)
      setState({ timeoutId: id, error: null })
    },
    [setState]
  )
  const capture = useCallback(
    async () => {
      if (webcamRef.current) {
        const image = webcamRef.current.getScreenshot()
        if (image && faceMatcher) {
          const blob = new Blob([Buffer.from(image.replace(new RegExp(/data:image\/jpeg;base64,/,), ''), 'base64')])
          const img = await bufferToImage(blob)
          const detections = await getFullFaceDescription(img)
          const recognition = detections.map((detection) => {
            const faceMatch = faceMatcher.findBestMatch(detection.descriptor)
            return {
              ...detection,
              label: faceMatch.label,
              distance: faceMatch.distance,
            }
          })
          const [face] = recognition.filter(recog => recog.label !== 'unknown')
          if (face) {
            setState((prev) => {
              if (prev.frames.length < 10) {
                return { frames: [...prev.frames, { key: prev.frames.length, face, image }], error: null }
              }
              return prev
            })
          }
        }
      }
    },
    [webcamRef, faceMatcher, setState]
  )
  useEffect(() => {
    const onSocket = async () => {
      socket.on('connect', () => {
        setState({ cardReaderConnected: true })
      })
      socket.on('disconnect', () => {
        setState({ cardReaderConnected: false })
      })
      socket.on('readIdCard', async (data) => {
        handleCardReader(data)
      })
    }
    if (event && event.options.face.idcard) {
      onSocket()
      return () => {
        socket.removeAllListeners()
        socket.disconnect()
      }
    }
    return () => {}
  }, [event, socket, handleCardReader, setState])
  useEffect(() => {
    if (frames.length === 10) {
      handleCheckin()
    }
  }, [frames, handleCheckin])
  useEffect(() => {
    if ((faceMatcher && !prevState.faceMatcher) || (prevState && prevState.frames.length < frames.length)) {
      clearTimeout(timeoutId)
      if (!error) {
        const id = setTimeout(() => {
          setState({ warning: 'Recognition timeout, Please manual check-in!' })
        }, RECOGNITION_TIMEOUT)
        setState({ timeoutId: id })
      }
    }
    return () => clearTimeout(timeoutId)
  }, [frames, faceMatcher, timeoutId, prevState, setState, error, warning])
  useInterval(() => {
    if (participant && frames.length < 10 && !error && !warning) {
      capture()
    }
  }, 200)
  const isChildren = useMemo(() => (participant && participant.options && participant.options.children), [participant])
  if (!event) {
    return <Redirect to="/events" />
  }
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={handleSearch} className={classes.form}>
          <TextField
            autoFocus
            fullWidth
            label="Search"
            placeholder="Enter bib number or ID/Passport"
            variant="filled"
            style={{ margin: 0 }}
            value={search}
            onChange={e => setState({ search: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Fab
                    className={cardReaderConnected ? classes.searchButton : undefined}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Search"
                    onClick={handleSearch}
                    disabled={search === ''}
                  >
                    <SearchIcon className={classes.extendedIcon} />
                    {cardReaderConnected ? 'Insert card or search' : 'Search'}
                  </Fab>
                  <Fab
                    className={classes.clearButton}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Reset"
                    onClick={() => setState(blank)}
                  >
                    <RotateLeftIcon className={classes.extendedIcon} />
                    Reset
                  </Fab>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {participant ? (
          <Fragment>
            <div className={classes.detection}>
              <CardMedia id="photo-wrapper" className={classes.photoWrapper}>
                <img id="photo" className={classes.photo} src={participant.photo || event.logo} alt="Participant" />
              </CardMedia>
              {!isChildren ? (
                <CardMedia className={classes.webcam}>
                  <Webcam
                    audio={false}
                    width={videoConstraints.width}
                    height={videoConstraints.height}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                </CardMedia>
              ) : null}
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" align="center">
                {`${participant.firstname} ${participant.lastname} (${participant.bib})`}
              </Typography>
              <Typography variant="h6" color="textSecondary" component="h4" align="center">
                {participant.gender.toUpperCase()} {participant.ageCategory}
              </Typography>
            </CardContent>
          </Fragment>
        ) : (
          <Fragment>
            <CardMedia className={classes.greeting}>
              <img className={classes.logo} src={event.logo} alt={event.name} />
              {loading && (<Loading />)}
            </CardMedia>
          </Fragment>
        )}
        {faceMatcher || error || warning ? (
          <CardActions>
            {faceMatcher && frames.length < 10 && !error && !warning ? (
              <SnackbarContentWrapper
                className={classes.message}
                variant="info"
                message={`Recognizing ... ${frames.length * 10}%`}
                action={undefined}
              />
            ) : null}
            {warning ? (
              <SnackbarContentWrapper
                className={classes.message}
                showIcon
                variant="info"
                message={warning}
                action={participant && (error !== 'Online Runner' && error !== 'RACE KIT') ? (
                  <Button color="inherit" onClick={() => (isChildren ? childrenCheckin() : manualCheckin())}>
                    Check-in
                  </Button>
                ) : undefined}
              />
            ) : null}
            {error ? (
              <SnackbarContentWrapper
                className={classes.message}
                showIcon
                variant="error"
                message={error}
                action={participant && (error !== 'Online Runner' && error !== 'RACE KIT') ? (
                  <Button color="inherit" onClick={resumeCheckin}>
                    Check-in
                  </Button>
                ) : undefined}
              />
            ) : null}
          </CardActions>
          
        ) : null}
        {frames.length > 0 ? (
          <CardMedia className={classes.framesWrapper}>
            {frames.map(frame => (
              <img key={frame.key} className={classes.frame} src={frame.image} alt="frame" />
            ))}
          </CardMedia>
        ) : null}
      </Card>
    </div>
  )
}

export default FaceIDPage
