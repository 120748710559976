import React, { Fragment, useContext, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { List, Typography, IconButton } from '@material-ui/core'
import { ExitToApp as LogoutIcon } from '@material-ui/icons'
import { defaultConfig, Layout } from 'bearright-mui-layout'

import AuthContext from './contexts/AuthContext'
import EventContext from './contexts/EventContext'
import TitleContext from './contexts/TitleContext'
import Title from './components/Title'
import ListMenuItem from './components/ListMenuItem'
import PrivateRoute from './components/PrivateRoute'
import Footer from './components/Footer'
import NotFoundPage from './pages/NotFoundPage'
import LoginPage from './pages/LoginPage'
import StatsPage from './pages/StatsPage'
import ParticipantsPage from './pages/ParticipantsPage'
import SelectEventPage from './pages/SelectEventPage'
import CameraPage from './pages/CameraPage'
import FaceIDPage from './pages/FaceIDPage'
import Top100 from './pages/Top100'
import SlipPage from './pages/SlipPage'
import ManualCheckInPage from './pages/ManualCheckIn'
import QRScanPage from './pages/QRScan'

const App = () => {
  const { user, removeToken } = useContext(AuthContext)
  const { event, setEvent } = useContext(EventContext)
  const { setTitle } = useContext(TitleContext)
  useEffect(() => {
    setTitle(event ? event.name : 'Race Pack')
  }, [event, setTitle])
  const logout = () => {
    setEvent(null)
    removeToken()
  }
  if (!user) {
    return (
      <LoginPage />
    )
  }
  return (
    <Layout
      config={defaultConfig}
      header={(
        <Fragment>
          <Title />
          <Typography variant="h6" color="inherit" noWrap>{user.name}</Typography>
          <IconButton color="inherit" onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </Fragment>
      )}
      logo={(
        <Typography variant="h6" color="inherit" noWrap>Race pack - Staff</Typography>
      )}
      menus={(
        <List dense>
          <ListMenuItem exact path="/events" title="Events" />
          {event ? (
            <Fragment>
              <ListMenuItem exact path="/participants" title="Participants" />
              <ListMenuItem exact path="/faceid" title="FaceID" />
              <ListMenuItem exact path="/camera" title="Camera" />
              <ListMenuItem exact path="/top100" title="Top 100" />
              <ListMenuItem exact path="/manualcheckin" title="Manual" />

            </Fragment>
          ) : null}
        </List>
      )}
      footer={(
        <Footer poweredBy="Thairun" copyright="Copyright © 2019 Bear Right Technology Co.,Ltd." />
      )}
    >
      <Switch>
        <PrivateRoute exact path="/events" component={SelectEventPage} />
        <PrivateRoute exact path="/stats" component={StatsPage} />
        <PrivateRoute exact path="/participants" component={ParticipantsPage} />
        <PrivateRoute exact path="/faceid" component={FaceIDPage} />
        <PrivateRoute path="/slip/:activityId" component={SlipPage} />
        <PrivateRoute exact path="/camera" component={CameraPage} />
        <PrivateRoute exact path="/top100" component={Top100} />
        <PrivateRoute exact path="/manualcheckin" component={ManualCheckInPage} />
        <PrivateRoute exact path="/qrscan" component={QRScanPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  )
}

export default App
