import React, {
  useContext, useState, useCallback, Fragment,
} from 'react'
import { Redirect } from 'react-router-dom'
import {
  Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper, Toolbar, Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import XLSX from 'xlsx'

import EventContext from '../contexts/EventContext'
import activitiesCountQuery from '../graphql/queries/activitiesCount'
import participantReportQuery from '../graphql/queries/participantReport'
import Loading from '../components/Loading'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  title: {
    flex: '1 1 100%',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: spacing(1),
  },
}))

const ParticipantsPage = () => {
  const classes = useStyles()
  const [exporting, setExporting] = useState(false)
  const { event } = useContext(EventContext)
  const activitiesCount = useQuery(activitiesCountQuery, { variables: { eventId: event && event._id, interval: 60 }, fetchPolicy: 'network-only' })
  const participantReport = useQuery(participantReportQuery, { fetchPolicy: 'network-only', skip: true })
  const handleExport = useCallback(
    async () => {
      try {
        setExporting(true)
        const participantReportResult = await participantReport.refetch({ eventId: event && event._id })
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(participantReportResult.data.participantReport.map(({ event: { name: eventName }, options, ...participant }) => ({ ...participant, eventName, ...options })))
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Participants')
        XLSX.writeFile(workbook, `participants-${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
      } catch (err) {
        console.error(err)
      } finally {
        setExporting(false)
      }
    },
    [setExporting, event, participantReport]
  )
  if (!event) {
    return <Redirect to="/events" />
  }
  const allCheckin = activitiesCount.data && activitiesCount.data.activitiesCount.filter(activity => !!activity.lastActivity).reduce((prev, cur) => (prev + cur.count), 0)
  return (
    <Paper>
      <Toolbar>
        <Typography className={classes.title} variant="h5">Participants {allCheckin ? `(Checked in: ${allCheckin})` : null}</Typography>
        {!activitiesCount.loading ? (
          <Button variant="contained" color="secondary" onClick={handleExport} disabled={exporting}>
            {exporting ? 'Exporting ...' : 'Export'}
          </Button>
        ) : null}
      </Toolbar>
      {!activitiesCount.loading ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activitiesCount.data.activitiesCount.map(row => (
                <TableRow key={`${row.lastActivity}-${row.count}`}>
                  <TableCell>{row.lastActivity || '-'}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Toolbar>
            <Typography className={classes.title} variant="h5">Checkin by time</Typography>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activitiesCount.data.activitiesCountByTime.filter(activity => activity.time).map(row => (
                <TableRow key={`${row.time}-${row.count}`}>
                  <TableCell>{moment(row.time).format('YYYY-MM-DD HH:mm:SS') || '-'}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Fragment>
      ) : (
        <Loading />
      )}
    </Paper>
  )
}

export default ParticipantsPage
