import gql from 'graphql-tag'

export default gql`
query ($eventId: String! $search: String!) {
  participant (
    filter: {
    eventId: $eventId
    OR: [
      {
        bib: $search
      }
      {
        nationalId: $search
      }
    ]
  }
  ) {
    _id
    bib
    row
    firstname
    lastname
    displayName
    gender
    ageCategory
    nationality
    nationalId
    photo
    shirt {
      type
      size
    }
    lastActivity{
      photo
      status
    }
    activities{
      status
      createdAt
    }
    checkin{
      status
      photo
    }
    options
  }
}
`
