import React, {
  useMemo, useContext, useCallback,
} from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import {
  Typography, Card, CardMedia, CardContent, Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import qr from 'qr-image'

import Loading from '../components/Loading'
import activityIdQuery from '../graphql/queries/activityId'
import EventContext from '../contexts/EventContext'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'hidden',
  },
  print: {
    width: '72mm !important',
    margin: '16px auto',
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: spacing(1),
  },
  header: {
    alignSelf: 'flex-start',
  },
  card: {
    width: '100%',
  },
  logoWrapper: {
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  logo: {
    maxWidth: '100%'
  },
  photoWrapper: {
    height: 160,
    textAlign: 'center',
  },
  photo: {
    height: 160,
  },
  content: {
    textAlign: 'center',
    width: '100%',
    paddingBottom: '8px !important',
  },
  row: {
    width: 170,
    borderRadius: spacing(1),
    borderColor: palette.secondary.main,
    borderStyle: 'solid',
    padding: spacing(1, 2),
    margin: '0 auto',
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  distance: {
    margin: '0 auto',
    verticalAlign: 'middle',
    // marginTop: spacing(1),
    // marginBottom: spacing(1),
  },
  qrcode: {
    height: 140,
  },
  items: {
    display: 'flex',
    justifyContent: 'center',
  },
  shirtBox: {
    width: '40%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shirtType: {
    maxHeight: 120,
  },
  shirtSize: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '34px',
  },
  productBox: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  product: {
    maxWidth: '100%',
    maxHeight: 100,
  },
  line: {
    width: '100%',
  },
  button: {
    margin: spacing(0, 1),
  },
  option: {
    marginTop: spacing(2)
  },
  option2: {
    marginTop: spacing(0)
  }
}))

const SlipPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { activityId } = useParams()
  const { event } = useContext(EventContext)
  const { loading, data: { activityId: activity } = {} } = useQuery(activityIdQuery, { variables: { _id: activityId }, fetchPolicy: 'network-only' })
  const print = () => window.print({ preview: false,})
  const goToCheckin = useCallback(
    // () => history.push('/faceid'),
    () => history.goBack(),
    [history]
  )
  const qrcode = useMemo(() => {
    if (activity && activity.participant) {
      // return qr.imageSync(`https://runner.thai.run/${event.slug}/${activity.participant.bib}`, { type: 'png', parse_url: true })
      return qr.imageSync(`${activity.participant.bib}`, { type: 'png', parse_url: true })
    }
    return null
  }, [activity])
  if (loading) {
    return (
      <Loading />
    )
  }
  if (!event || !activity) {
    return (
      <Redirect to="/notfound" />
    )
  }
  const { participant } = activity
  console.log(participant)
  console.log(event)
  const shirt = event.shirts.reduce((prev, cur) => participant.shirt && (cur.type === participant.shirt.type || cur.slug === participant.shirt.type ? cur : prev), null)
  console.log(shirt)
  return (
    <div className={classes.root}>
      <div>
        <Button className={classes.button} onClick={goToCheckin}>Back</Button>
        <Button className={classes.button} variant="contained" color="secondary" onClick={print}>Print</Button>
      </div>
      <Card className={classes.print}>
        <CardMedia className={classes.logoWrapper}>
          <img className={classes.logo} src={event.logo} alt={event.name} />
        </CardMedia>
        {/* <CardMedia className={classes.photoWrapper}>
          <img className={classes.photo} src={activity.photo || participant.photo} alt="Participant" />
        </CardMedia> */}
        <Typography variant="h3">{participant.options.race ? participant.options.race : null}</Typography>

        <CardContent className={classes.content}>
          <Typography variant="h5" align="center">{participant.firstname} {participant.lastname}</Typography>

          <img className={classes.qrcode} src={`data:image/png;base64,${qrcode.toString('base64')}`} alt="QR Code" />
          <Typography variant="h5">{participant.gender.toUpperCase()} ({participant.ageCategory})</Typography>
          <Typography variant="h3">BIB {participant.bib}</Typography>
          <div className={classes.row}>
            <Typography variant="h4">{participant.row}</Typography>
          </div>
          {participant.options && participant.options.ticketType.includes('บัตรวีไอพี', 'บัตรการกุศล') !== true ? (
            <Typography variant="subtitle1">{participant.options.ticketType}</Typography>
          ): null}

          {participant.options.status ? (
            <Typography variant="h5">{participant.options.status}</Typography>
          ) : null}
          
          <div className={classes.items}>
            <div className={classes.shirtBox}>
              {shirt ? (
                <img className={classes.shirtType} src={shirt.image} alt={shirt.type} />
              ) : null}
              {participant.shirt ? (
                <Typography variant="h3" className={shirt ? classes.shirtSize : null}>{participant.shirt.size}</Typography>
              ) : null}
            </div>
            {/* {participant.options && participant.options.additionalProduct ? (
              <div className={classes.productBox}>
                <img className={classes.product} src="https://cdn.nativesrc.com/thairun/bs10-2020/70740892-6D08-4B8B-846A-0FCB6D286331.png" alt="UD" />
              </div>
            ) : null} */}
          </div>
          {participant.shirt ? (
            <Typography className={classes.option2} variant="subtitle2">{`${participant.shirt.type} ${participant.shirt.size}`}</Typography>
          ) : null}
          {participant.options && participant.options.vipShirt ? (<React.Fragment>
              <hr className={classes.line} />
              <Typography className={classes.option} variant="subtitle1">{participant.options.vipShirt}</Typography>
            </React.Fragment>
          ) : null}
          {participant.options && participant.options.firstHalf === 'First Half' ? (
            <Typography className={classes.option} variant="h5">First Half</Typography>
          ) : null}
          {participant.options.additionalProduct ? (
            <Typography variant="h5" align="left">ท่านมีสินค้า Pre-Order</Typography>
          ) : null}
          {participant.options && participant.options.firstFull ? (
            <Typography className={classes.option} variant="h5">{participant.options.firstFull}</Typography>
          ) : null}
          <hr className={classes.line} />
          <Typography variant="body1">{moment(activity.createdAt).format('DD MMM YYYY, HH:mm:ss')}</Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default SlipPage
