import gql from 'graphql-tag'

export default gql`
query ($eventId: String!) {
  activitiesCountByTicketType(eventId: $eventId) {
      _id
    count
    stock
  }
}
`
