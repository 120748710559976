import gql from 'graphql-tag'

export default gql`
query ($_id: MongoID!) {
  activityId (_id: $_id) {
    _id
    participant {
      _id
      bib
      row
      firstname
      lastname
      displayName
      gender
      ageCategory
      nationality
      nationalId
      photo
      shirt {
        type
        size
      }
      status
      options
    }
    photo
    status
    createdAt
  }
}
`
